import { call, put, takeLatest } from '@redux-saga/core/effects'
import {
  setAuth,
  setCustomerInfo,
  setHasLoggedInByZaloOa,
  setShowSplashing
} from '../../actions/auth.action'
import { checkBinding, checkPayable } from '../../actions/autodebit.action'
import { AUTH } from '../../constants/ActionType'
import { phoneOtpVerification } from '../../constants/AppConstants'
import { ZALOPAY_APP_ID, ZALO_REDIRECT } from '../../constants/Config'
import history from '../../history'
import * as AuthServices from '../../services/auth.services'
import store from '../../store'
import * as actionTypes from './actionTypes'

function* handleLoginWithZalo(payload) {
  const accessRes = yield call(AuthServices.getZaloAccessCode, payload.authCode)
  // const { data } = accessRes;
  try {
    if (accessRes && accessRes.data) {
      const { data } = accessRes

      if (data.access_token) {
        const authRes = yield call(AuthServices.loginWithZaloAccessTokenCus, data.access_token)

        if (authRes && authRes.error && authRes.error.code === 1) {
          const { customerInfo } = authRes.data
          const customerData = {
            customerPhone: customerInfo.phone,
            customerId: customerInfo.customerId
          }
          yield put(setCustomerInfo(customerData))
          yield put(setAuth(customerInfo))
          yield put(setHasLoggedInByZaloOa(true))
          yield put(setShowSplashing(false))

          yield put(checkBinding())
          yield put(checkPayable())

          history.push(`/`)
        } else if (authRes && authRes.error && authRes.error.code == 104) {
          history.push(
            `/otp_verification?zaloId=${authRes.data.zalo_id}&loginFlowId=${authRes.data.login_flow_id}`
          )
        } else if (authRes && authRes.code === 5) {
          console.log('authRes.code === 5')

          //invalid session, clear session
          //Redirect to zalo login

          // sessionStorage.removeItem("Session");
          // document.cookie = "";
          window.location.href = ZALO_REDIRECT
        } else {
          // sessionStorage.removeItem("Session");
          // document.cookie = "";
          // window.location.href = ZALO_REDIRECT;
        }
      }
    }
  } catch (e) {
    // yield put(setShowSplashing(false));
    // history.replace(`/error-page`);
    // sessionStorage.removeItem("Session");
    // document.cookie = "";
    window.location.href = ZALO_REDIRECT
    console.log(e)
  }
}

function* handleLoginWithZalopay() {
  try {
    const ZaloPay = window.ZaloPay
    ZaloPay.isZaloPay = true
    ZaloPay.getUserInfo(ZALOPAY_APP_ID, async (res) => {
      let phone = res.data.phonenumber
      const customer_name = res.data.displayname ? res.data.displayname : ''
      const gender = res.data.gender
      const muid = res.data.muid
      const maccess_token = res.data.maccess_token
      const zalopay_user_id = res.data.user_id
      if (phone && `${phone}`.substring(0, 2) == '84') {
        phone = '0' + `${phone}`.slice(2)
      }
      const authRes = await AuthServices.loginWithZalopay({
        phone,
        gender,
        muid,
        maccess_token,
        customer_name,
        zalopay_user_id
      })

      if (authRes.error.code === 5 || authRes.error.code === 104) {
        history.push(
          `/otp_verification?zaloId=&loginFlowId=${authRes.data.login_flow_id}&muid=${muid}`
        )
      } else {
        const { customerInfo } = authRes.data
        const customerData = {
          customerPhone: customerInfo.phone,
          customerId: customerInfo.customerId
        }
        store.dispatch(setCustomerInfo(customerData))
        store.dispatch(setAuth(customerInfo))
        store.dispatch(setHasLoggedInByZaloOa(true))

        store.dispatch(checkBinding())
        store.dispatch(checkPayable())
      }
    })
  } catch (e) {
    console.log(e)
  } finally {
    store.dispatch(setShowSplashing(false))
  }
}

function* handleVerifyOtp({ verifyData }) {
  try {
    const { setErrorData, setError, body, setStep } = verifyData
    const data = yield call(AuthServices.verifyOTP, body)
    if (data.error && data.error.code === 1) {
      const { customerInfo } = data.data
      const customerData = {
        customerPhone: customerInfo.phone,
        customerId: customerInfo.customerId
      }
      yield put(setCustomerInfo(customerData))
      yield put(setAuth(customerInfo))
      yield put(setHasLoggedInByZaloOa(true))
      yield put(setShowSplashing(false))
      history.replace(`/`)
    } else if (data.error && data.error.code === 108) {
      setError('Mã xác thực không đúng, vui lòng kiểm tra lại')
    } else if (
      data.error &&
      (data.error.code === 105 ||
        data.error.code === 106 ||
        data.error.code === 113 ||
        data.error.code === 114)
    ) {
      setStep(phoneOtpVerification.step.result)
      setErrorData(data.error)
    }
  } catch (error) {
    console.log('error', error)
  }
}

function* handleRequestOtp({ data }) {
  try {
    const { body, onSuccess, onFailed, setErrorData, setStep } = data
    const response = yield call(AuthServices.sendOTPRequest, body)
    if (response.error && response.error.code === 1) {
      onSuccess()
    } else if (response.error && (response.error.code === 112 || response.error.code === 114)) {
      setStep(phoneOtpVerification.step.result)
      setErrorData(response.error)
    } else {
      onFailed(response.error.messageVN)
    }
  } catch (err) {
    console.log('err', err)
  }
}

export default function* authSaga() {
  yield takeLatest(actionTypes.LOGIN_WITH_ZALO, handleLoginWithZalo)
  yield takeLatest(actionTypes.VERIFY_OTP, handleVerifyOtp)
  yield takeLatest(actionTypes.LOGIN_WITH_ZALOPAY, handleLoginWithZalopay)
  yield takeLatest(AUTH.REQUEST_OTP, handleRequestOtp)
}
