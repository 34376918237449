import { Input } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Grid from '@material-ui/core/Grid'
import BorderColorIcon from '@material-ui/icons/BorderColor'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import ReceiveCardDialog from '../../components/ReceiveCardDialog'
import ZPButton from '../../components/ZPButton'
import ZPSubTitle from '../../components/ZPSubTitle'
import { COLOR_BLACK, COLOR_BLUE } from '../../constants/Styles'
import { post } from '../../utils/apiCallerV2'
import { color } from '../../utils/color'
import { getMobileOperatingSystem, removeBodyBg, setTitle } from '../../utils/helper'
import NoticePage from '../../components/NoticePage'
import moment from 'moment'

const ZaloPay = window.ZaloPay
const errorStyle = {
  margin: '0px',
  textAlign: 'left',
  paddingLeft: '10px',
  color: 'red'
}
class ReceiveCardPage extends Component {
  constructor(props) {
    super(props)
    setTitle('Nhận thẻ và xác thực')
    removeBodyBg()
    this.state = {
      inHome: true,
      openDialog: false,
      typedAddress: '',
      isEditting: false,
      errors: ''
    }
  }

  onHandClick = async () => {
    if (!this.state?.typedAddress) {
      this.setState({
        errors: 'Địa chỉ không được rỗng!',
        typedAddress: ''
      })

      return
    }

    const data = {
      received_address: this.state.typedAddress.trim()
    }
    const resp = await post('v2/customer/update-received-address', data)

    if (resp && resp.error.code !== 1) {
      this.setState((prevState) => ({
        errorFields: {
          ...prevState.errorFields
        }
      }))
    } else {
      this.setState({
        openDialog: true,
        isEditting: false
      })
      setTimeout(() => {
        this.onCloseDialog()
        this.props.history.replace('/')
      }, 1000)
    }
  }

  onCloseDialog = () => {
    this.setState({
      openDialog: false
    })
  }

  onAddressFieldChange = (event) => {
    if (event.target) {
      this.setState({
        typedAddress: event.target.value
      })
    } else {
      this.setState({
        typedAddress: event,
        errors: ''
      })
    }
  }

  callAPIToOpenFacebookApp = () => {
    if (typeof ZaloPay !== 'undefined' && ZaloPay.isZaloPay) {
      ZaloPay.launchExternalApp({
        url: 'fb://page/640990189682280',
        packageId: 'com.facebook.katana'
      })
    }
  }

  callAPIToOpenZaloApp = () => {
    if (typeof ZaloPay !== 'undefined' && ZaloPay.isZaloPay) {
      ZaloPay.launchExternalApp({
        url: 'zalo://zaloapp.com/qr/link/3820907403981754700',
        packageId: 'com.zing.zalo'
      })
    }
  }

  componentDidMount() {
    this.setState({
      typedAddress: this.props.auth?.user?.received_address || ''
    })
  }

  calculateContentHeight() {
    return this.state.inHome
      ? this.state.originTypedAddress == 0
        ? 'calc(100vh - 296px)'
        : 'calc(100vh - 220px)'
      : 'calc(100vh - 195px)'
  }

  render() {
    const { inHome, openDialog } = this.state
    const maintenanceDay = moment('00:00:00 12/09/2024', 'HH:mm:ss DD/MM/YYYY')

    if (moment().isAfter(maintenanceDay)) {
      return (
        <NoticePage
          img={'/img/promotion/maintenance.svg'}
          title={'Hệ thống đang bảo trì'}
          content={'Vui lòng quay lại sau, cám ơn bạn!'}
          action={() => this.props.history.replace('/')}
        />
      )
    }

    return (
      <Fragment>
        <ReceiveCardDialog
          open={openDialog}
          onCloseClickOut={() => {
            this.onCloseDialog()
          }}
        />
        <Grid
          container
          spacing={3}
          direction="row"
          justifyContent="center"
          alignItems="center"
          style={{ paddingTop: '20px' }}
        >
          {!inHome && (
            <Fragment>
              <Grid item xs>
                <img
                  alt="receiving card at booths icon inactive"
                  src="/img/card/in-station-active.svg"
                  style={{ width: '70px' }}
                />
              </Grid>
              <Grid item xs>
                <img
                  alt="receiving card at home icon inactive"
                  src="/img/card/in-home-inactive.svg"
                  style={{ width: '70px' }}
                  onClick={() => {
                    this.setState({
                      inHome: true
                    })
                  }}
                />
              </Grid>
            </Fragment>
          )}
          {inHome && (
            <Fragment>
              <Grid item xs>
                <img
                  alt="receiving card at booths icon inactive"
                  src="/img/card/in-station-inactive.svg"
                  style={{ width: '54px' }}
                  onClick={() => {
                    this.setState({
                      inHome: false
                    })
                  }}
                />
              </Grid>
              <Grid item xs>
                <img
                  alt="receiving card at home icon active"
                  src="/img/card/in-home-active.svg"
                  style={{ width: '70px' }}
                />
              </Grid>
            </Fragment>
          )}
        </Grid>
        <Grid container spacing={3} direction="row" justifyContent="center" alignItems="center">
          <Grid item xs>
            <p style={{ color: !inHome ? color.blue[800] : 'gray' }}>Tại quầy (Miễn phí)</p>
          </Grid>
          <Grid item xs>
            <p style={{ color: inHome ? color.blue[800] : 'gray' }}>Tận nơi (Có phí)</p>
          </Grid>
        </Grid>
        <ZPSubTitle fullWidth style={{ margin: '0px 0px 20px 0px' }}>
          Hướng dẫn thực hiện
        </ZPSubTitle>
        <div
          className="page page-no-bottom"
          style={{ paddingTop: 0, paddingBottom: '81px', overflow: 'auto' }}
        >
          <p style={{ color: COLOR_BLACK, textAlign: 'left' }}>
            Nhận thẻ UniPass và xác thực {inHome ? 'tận nơi' : 'tại quầy'}:{' '}
          </p>
          <ul style={{ paddingLeft: 15, textAlign: 'justify' }}>
            <li style={{ margin: '10px 0px' }}>
              <span style={{ color: COLOR_BLACK }}>
                <strong>Phí dịch vụ: </strong>
              </span>
              {inHome ? '15.000đ / thẻ' : 'Miễn phí'}
            </li>
            <li style={{ margin: '10px 0px', color: COLOR_BLUE }}>
              <span style={{ color: COLOR_BLACK }}>
                <strong>Địa điểm:</strong>
                <br></br>
                {inHome ? (
                  this.state.originTypedAddress == 0 || this.state.isEditting ? (
                    <Fragment>
                      <Input
                        style={{ width: '100%' }}
                        placeholder="Nhập địa chỉ muốn nhận thẻ tại đây"
                        className="location-search-input"
                        value={this.state.typedAddress}
                        onChange={this.onAddressFieldChange}
                      />
                      <p style={errorStyle}>{this.state.errors}</p>
                      {this.state.isEditting ? (
                        <Button
                          style={{ marginTop: '5px' }}
                          variant="contained"
                          color="primary"
                          onClick={() => {
                            this.onHandClick()
                          }}
                        >
                          Cập nhật
                        </Button>
                      ) : null}
                    </Fragment>
                  ) : (
                    <div>
                      {this.state.typedAddress}
                      <BorderColorIcon
                        onClick={() => {
                          this.setState({
                            isEditting: true
                          })
                        }}
                        style={{ paddingLeft: '5px' }}
                      />
                    </div>
                  )
                ) : (
                  <span>
                    Trạm xe buýt Sài Gòn - 01 Phạm Ngũ Lão, Phường Nguyễn Thái Bình, Quận 01, TP.HCM
                  </span>
                )}
              </span>
            </li>
            <li style={{ margin: '10px 0px', color: COLOR_BLUE }}>
              <span style={{ color: COLOR_BLACK }}>
                <strong> {!inHome ? 'Thời gian hoạt động' : 'Thời gian giao thẻ'}:</strong>
                <br></br>
                {inHome ? (
                  '8:00 - 17:00 hằng ngày, trong vòng 7 đến 14 ngày làm việc từ khi cập nhật thông tin nhận thẻ'
                ) : (
                  <>
                    9:00 - 18:00, thứ 5 hằng tuần <br /> Nghỉ trưa lúc 12:00 - 13:00
                  </>
                )}
              </span>
            </li>
            <li style={{ margin: '10px 0px', color: COLOR_BLUE }}>
              <span style={{ color: COLOR_BLACK }}>
                <strong>Lưu ý:</strong>
                <br></br>
                {inHome && (
                  <span>
                    {' '}
                    Hiện tại UniPass chỉ hỗ trợ giao thẻ trong phạm vi thành phố Hồ Chí Minh.
                    <br></br>
                  </span>
                )}
                Nếu bạn thuộc đối tượng ưu tiên, vui lòng
                <span>{inHome ? ' xuất trình' : ' mang theo'}</span> giấy tờ chứng minh đối tượng
              </span>
            </li>
          </ul>
          <p
            style={{
              color: COLOR_BLACK,
              textAlign: 'left',
              fontStyle: 'italic'
            }}
          >
            Tài khoản ưu tiên chưa được xác thực sẽ áp dụng mức giá phổ thông.
          </p>
          <p style={{ color: COLOR_BLACK, textAlign: 'left', marginBottom: 0 }}>
            <strong>Thông tin liên hệ</strong>
          </p>
          <ul style={{ paddingLeft: 15, textAlign: 'justify', marginTop: 0 }}>
            {!this.props.auth.hasLoggedInByZaloOa && (
              <Fragment>
                <li style={{ margin: '10px 0px', color: COLOR_BLUE }}>
                  <span style={{ color: COLOR_BLACK }}>
                    Fanpage:{' '}
                    {getMobileOperatingSystem() === 'iOS' ? (
                      <a href="fb://profile/640990189682280">http://fb.me/Unipass.vn</a>
                    ) : (
                      <a href="" onClick={this.callAPIToOpenFacebookApp}>
                        http://fb.me/Unipass.vn
                      </a>
                    )}
                  </span>
                </li>
                <li style={{ margin: '10px 0px', color: COLOR_BLUE }}>
                  <span style={{ color: COLOR_BLACK }}>
                    Zalo:{' '}
                    {getMobileOperatingSystem() === 'iOS' ? (
                      <a href="http://zalo.me/3820907403981754700">
                        http://zalo.me/3820907403981754700
                      </a>
                    ) : (
                      <a href="" onClick={this.callAPIToOpenZaloApp}>
                        http://zalo.me/3820907403981754700
                      </a>
                    )}
                  </span>
                </li>
              </Fragment>
            )}
            {this.props.auth.hasLoggedInByZaloOa && (
              <Fragment>
                <li style={{ margin: '10px 0px', color: COLOR_BLUE }}>
                  <span style={{ color: COLOR_BLACK }}>
                    Fanpage:{' '}
                    {/* {getMobileOperatingSystem() === "iOS" ? (
                      <a href="fb://page/640990189682280">
                        http://fb.me/Unipass.vn
                      </a>
                    ) : (
                      <a href="fb://page/640990189682280">
                        http://fb.me/Unipass.vn
                      </a>
                    )} */}
                    <a href="fb://profile/640990189682280">http://fb.me/Unipass.vn</a>
                  </span>
                </li>
                <li style={{ margin: '10px 0px', color: COLOR_BLUE }}>
                  <span style={{ color: COLOR_BLACK }}>
                    Zalo:{' '}
                    <a href="http://zalo.me/3820907403981754700">
                      http://zalo.me/3820907403981754700
                    </a>
                  </span>
                </li>
              </Fragment>
            )}
            <li style={{ margin: '10px 0px', color: COLOR_BLUE }}>
              <span style={{ color: COLOR_BLACK }}>
                HotLine: <a href="tel:1900633314">1900 633 314</a>
              </span>
            </li>
          </ul>
          {inHome ? (
            <FormControlLabel
              style={{ textAlign: 'left' }}
              control={
                <Checkbox
                  checkedIcon={<CheckCircleIcon style={{ color: '#008fe5' }} />}
                  checked={true}
                />
              }
              label="Tôi cam kết thông tin chính xác và sẽ nhận thẻ UniPass"
            />
          ) : (
            <img
              src="/images/busstation.png"
              alt="bus_station"
              style={{ width: 'calc(100% - 50px)' }}
            />
          )}
        </div>
        {inHome && (
          <div
            className="bottom-fixed-button-wrapper"
            style={{ padding: '12px 0', backgroundColor: '#fff', zIndex: 1 }}
          >
            {this.state.originTypedAddress == 0 ? (
              <ZPButton
                onClick={() => {
                  this.onHandClick()
                }}
                fullWidth
                style={{ margin: 0 }}
              >
                Gửi yêu cầu
              </ZPButton>
            ) : null}
          </div>
        )}
      </Fragment>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth
  }
}

export default connect(mapStateToProps, null)(withRouter(ReceiveCardPage))
